import logo from './logo.svg';
import './App.css';
import React from "react";
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import KOLList from "./Kol";
import ProjectPage from "./Project";
import NavBar from "./NavBar";
import SearchPage from "./Search";
import {Content, Header} from "antd/es/layout/layout";
import {Button, Input, Layout} from "antd";
import LoginForm from "./login";
import RecentFollow from './RecentFollow';
import RecentKol from './RecentKol';
function App() {
  return (

      <Router>
          <Layout>
              <Header>
                  <NavBar />
              </Header>
              <Content style={{ padding: '0 50px' }}>
                  <Routes>
                      <Route path="/project" element={<ProjectPage />} />
                      <Route path="/kol" element={<KOLList />} />
                      <Route path="/login" element={<LoginForm />} />
                      <Route path="/search" element={<SearchPage />} />
                      <Route path="/recentfollow" element={<RecentFollow />} />
                      <Route path="/recentkol" element={<RecentKol />} />
                  </Routes>
              </Content>
          </Layout>
      </Router>
  );
}

export default App;
