import React, { useState, useEffect, useRef} from 'react';
import { Table, Button, Upload, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import axios from 'axios';
import {useNavigate} from "react-router-dom";

axios.defaults.baseURL = 'http://54.250.82.236:5001';
const KOLList = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate(); // 用于实现页面跳转

    useEffect(() => {
        fetchKOLs();
    }, []);

    const fetchKOLs = async () => {
        setLoading(true);
        
        await axios.get('/kol', {
            headers: {
                'x-access-token': localStorage.getItem('token')
            }
        }).then((response) => {
            console.log(response.status)
            if(!response.status || response.status !== 200) {
                navigate('/login');
            }
            var excel_data = response.data['data'];
            for(let i = 0; i < excel_data.length; i++) {
                excel_data[i]['id'] = i + 1;
                excel_data[i]['is_monitor'] = (excel_data[i]['is_monitor'] === 1 ? '是': '否');
            }
            setData(excel_data);
            console.log(response.data);
        }).catch((error) => {
            console.log(error)
            navigate('/login');
        }) ; // 你的API路径
        
        setLoading(false);
    };

    const columns = [
        { title: 'ID', dataIndex: 'id' },
        { title: 'KID', dataIndex: 'kid' },
        { title: '账户URL', dataIndex: 'account_url',  render: (text) => <a href={text} target="_blank" rel="noopener noreferrer">{text}</a>,},
        { title: '账户类型', dataIndex: 'account_type' },
        { title: '账户子类型', dataIndex: 'account_subtype' },
        { title: '账户子类型2', dataIndex: 'account_subtype2' },
        { title: 'Alpha', dataIndex: 'alpha' },
        { title: '描述', dataIndex: 'desc' },
        { title: '是否监控', dataIndex: 'is_monitor' },
        { title: '创建时间', dataIndex: 'create_time' },
        { title: '更新时间', dataIndex: 'update_time' },
    ];

    const uploadProps = {
        name: 'kol_file',
        action: axios.defaults.baseURL + '/kol', // 你的上传Excel的API路径
        headers: {
            'x-access-token': localStorage.getItem('token')
        },
        onChange(info) {
            if (info.file.status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (info.file.status === 'done') {
                message.success(`${info.file.name} 文件上传成功`);
                fetchKOLs();
            } else if (info.file.status === 'error') {
                message.error(`${info.file.name} 文件上传失败`);
            }
        },
    };

    const inputRef = useRef();

    const handleClick = () => {
        inputRef.current.click();
    };

    const handleUploadFile = (event) => {
        const data = new FormData();
        data.append('kol_file', event.target.files[0]);
        console.log('data, ', data);

        axios.post('/kol', data)
            .then(response => {
                // 处理成功响应
                console.log(response);
                if(response.status === 200) {
                    message.success(`文件上传成功`);
                    fetchKOLs();
                }
            })
            .catch(error => {
                // 处理错误响应
                console.error(error);
                message.error(`文件上传失败`);
            });
    };

    return (
        <div>
            <Table columns={columns} dataSource={data} loading={loading} rowKey="id"  pagination={{
                pageSize: 10
            }} />
            <Upload {...uploadProps}>
                <Button icon={<UploadOutlined />}>上传</Button>
                最多上传155名用户
            </Upload>
        </div>
    );
};

export default KOLList;
